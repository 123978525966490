import React, { createContext, useContext, useState } from 'react';

const FatalErrorContext = createContext();

export const useFatalError = () => useContext(FatalErrorContext);

export const FatalErrorProvider = ({ children }) => {
  const [ error, setError ] = useState({ show: false, description: 'Prova' });

  const showError = (description) => setError({ show: true, description });
  const hideError = () => setError({ show: false, description: '' });

  return (
    <FatalErrorContext.Provider value={ { error, showError, hideError } }>
      { children }
      <FatalError show={ error.show } description={ error.description } />
    </FatalErrorContext.Provider>
  );
};

const FatalError = ({ show, description }) => {
  return (
    show && (
      <div className="fixed z-[99999999] w-full h-full top-0 left-0 bg-[#0050a3] text-white flex justify-center items-center flex-col space-y-2" role="alert">
        <h4 className="font-bold text-2xl">Fatal Error!</h4>
        <p className='text-xl'>{ description }</p>
        <button className="btn btn-primary bg-gray-100 border px-2 rounded-md border-gray-200 text-gray-800 hover:bg-gray-300" onClick={ () => window.location.reload() }>Reload</button>
      </div>
    )
  );
};