import React, { useState, useEffect, createContext, useContext } from "react";
import PropTypes from "prop-types";
import Modality from "../../components/Modality/Modality";
import Style from "../../assets/styles";
import { clsx } from "../../modules/Utilkit/Utilkit";
import Flaticon from "../../components/Flaticon/Flaticon";
import { FormifyCombobox, FormifyComboboxOption } from "../../components/Formify/Formify";

// Create context
const GenericModalContext = createContext();

export const useGenericModal = () => useContext(GenericModalContext);

export const GenericModalProvider = ({ children }) => {
  const [modalProps, setModalProps] = useState({
    show: false,
    label: '',
    description: '',
    icon: { name: '', type: '', className: '' },
    submit: { label: '', onSubmit: () => {} },
    cancel: { label: '', onCancel: () => {} }
  });

  const handleClose = () => setModalProps({ ...modalProps, show: false });

  return (
    <GenericModalContext.Provider value={{ setGenericModal: (props) => setModalProps({
      show: false,
      label: '',
      description: '',
      icon: { name: '', type: '', className: '' },
      submit: { label: '', onSubmit: () => {} },
      cancel: { label: '', onCancel: () => {} },
      ...props
    }) }}>
      {children}
      <GenericModal
        show={modalProps.show}
        onClose={handleClose}
        onSubmit={modalProps.submit.onSubmit}
        fields={[]}
        title={modalProps.label}
        buttons={[
          { name: modalProps.submit.label, onClick: modalProps.submit.onSubmit },
          { name: modalProps.cancel.label, onClick: () => modalProps.cancel.onCancel({ handleClose }) }
        ]}
        initialData={{}}
        icon={modalProps.icon}
      >
        {modalProps.description}
      </GenericModal>
    </GenericModalContext.Provider>
  );
};

const GenericModal = ({ show, onClose, onSubmit, fields, title, buttons, initialData, fetchData, id, children, icon }) => {
  const [ data, setData ] = useState(initialData);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if (id && show && fetchData) {
      setIsLoading(true);
      fetchData(id).then(res => {
        if (res.success) {
          setData(res.data);
        } else {
          // handle error
        }
        setIsLoading(false);
      });
    }
  }, [ id, show ]);

  const handleDataChange = (key, value) => {
    setData(curr => ({ ...curr, [ key ]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await onSubmit && onSubmit(data);
    setIsLoading(false);
  };

  return (
    <Modality show={ show } onClose={ onClose } onSubmit={ handleSubmit } buttons={ buttons } width="320px" icon={ icon }>
      <div className="text-xl">{ title }</div>
      <div className="mt-2">{ children }</div>
      { fields.map(field => (
        <div className="flex flex-col w-full" key={ field.name }>
          <label htmlFor={ field.name }>{ field.label }</label>
          { field.type === "input" ? (
            <input
              required={ field.required }
              className={ Style.FormifyInput.Default}
              disabled={ isLoading }
              value={ data[ field.name ] }  
              onInput={ (e) => handleDataChange(field.name, e.target.value) }
            />
          ) : (
            <FormifyCombobox
              required={ field.required }
              className={ Style.FormifyCombobox.Default }
              disabled={ isLoading }
              value={ data[ field.name ] }
              options={ field.options }
              onChange={ (val) => handleDataChange(field.name, val) }
            >
              { field.options.map(({ value, label }) => Style.FormifyCombobox.Default.ComboboxOption({ value, label, isSelected: value === data[ field.name ] })) }
            </FormifyCombobox>
          ) }
        </div>
      )) }
    </Modality>
  );
};

GenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    required: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    }))
  })).isRequired,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node).isRequired,
  initialData: PropTypes.object.isRequired,
  fetchData: PropTypes.func,
  id: PropTypes.string
};

export default GenericModal;
