import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Style from '../../../assets/styles';
import { FormifyCombobox } from '../../../components/Formify/Formify';
import { SHORT_DAY_NAMES } from '../../../config/costants';
import DICTIONARY from '../../../config/dict';
import { useTracer } from '../../../modules/Request';
import { clsx, sieve } from '../../../modules/Utilkit/Utilkit';
import { useAuth } from '../../components/Auth';
import { useContextMenu } from '../../components/ContextMenu';
import { useGenericModal } from '../../components/GenericModal';
import { useLoadingContext } from '../../components/LoadingScreen';
import { useNotifyContext } from '../../components/Notify';

//#region [costants]
const TIMESHEET_TYPES = [
  { value: 'JOB', label: 'Job', pos: 0 },
  { value: 'NOREC', label: 'No Recovery', pos: 2 }
];
//#endregion

const TimesheetContext = createContext();

export const TimesheetConsumer = () => useContext(TimesheetContext);

//#region [comp] TimesheetsPage - Main component for the timesheets page
const TimesheetsPage = () => {
  //#region [contexts]
  const { notify } = useNotifyContext();

  const { setShowLoadingScreen } = useLoadingContext();

  const { setGenericModal } = useGenericModal();
  //#endregion

  //#region [costants]
  const CURRENT_MONTH = new Date().toISOString().split('T')[ 0 ].slice(0, 7);
  //#endregion

  //#region [states]
  const [ selectedMonth, setSelectedMonth ] = useSelectedMonth(CURRENT_MONTH);
  //#endregion


  //#region [costants]
  const notWorkDays = [ SHORT_DAY_NAMES.indexOf('Su') ];

  const maybeWorkDays = [ SHORT_DAY_NAMES.indexOf('Sa') ];
  //#endregion

  //#region [hooks]
  const [ activities ] = useTracer('ts-activities');

  const [ calendar ] = useTracer('ts-calendar');
  //#endregion

  //#region [computed]
  const holidays = useMemo(() => calendar.reduce((acc, curr) => [ ...acc, curr.holidayDate ], []), [ calendar ]);

  const [ jobs, quotes, norecovery, rdproject ] = useMemo(() => {
    const jobs = [], quotes = [], norecovery = [], rdprojet = [];


    if (!activities) return [ jobs, quotes, norecovery ];

    activities.forEach(activity => {
      if (activity.type === 'JOB') jobs.push(activity);
      else if (activity.type === 'QUOTE') quotes.push(activity);
      else if (activity.type === 'NOREC') norecovery.push(activity);
      else if (activity.type === 'RDPRO') rdprojet.push(activity);
    });
    const sortedJobs = jobs.sort((a, b) => b.job.localeCompare(a.job) || a.wbs.localeCompare(b.wbs) || a.activity - b.activity);
    const sortedQuotes = quotes.sort((a, b) => a.job.localeCompare(b.job) || a.activity - b.activity);
    const sortedNorecovery = norecovery.sort((a, b) => a.activity - b.activity);
    const sortedRdprojet = rdprojet.sort((a, b) => a.activity - b.activity);

    return [ sortedJobs, sortedQuotes, sortedNorecovery, sortedRdprojet ];
  }, [ activities ]);

  const [ timesheets, insTimesheet, updTimesheet, delTimesheet ] = useTracer('ts-timesheets', {
    selectReqData: { month: selectedMonth.value },
    sort: (a, b) => a.type?.localeCompare(b.type) || a.job?.localeCompare(b.job) || a.wbs?.localeCompare(b.wbs) || a.activityCode?.localeCompare(b.activityCode),
    handleError: (error) => {
      notify('Error', error === 'already-exists' ? 'Timesheet already exists' : 'An error occurred', 'error');
    },
    onStartLoading: () => setShowLoadingScreen(true),
    onFinishLoading: () => setShowLoadingScreen(false),
    deleteFilter: (payload, item) => {
      if (payload.type === 'JOB') {
        return item.type + item.job + item.wbs + item.activityCode + item.activityDate.slice(0, 7) !== payload.type + payload.job + payload.wbs + payload.activityCode + payload.month;
      } else if (payload.type === 'QUOTE') {
        return item.type + item.job + item.activityCode + item.activityDate.slice(0, 7) !== payload.type + payload.job + payload.activityCode + payload.month;
      } else if (payload.type === 'NOREC' || payload.type === 'RDPRO') {
        return item.type + item.activityCode + item.activityDate.slice(0, 7) !== payload.type + payload.activityCode + payload.month;
      }

      return true;
    },
    insertFilter: (payload, item) => {
      if (payload.type === 'JOB') {
        return item.type + item.job + item.wbs + item.activityCode + item.activityDate.slice(0, 7) === payload.type + payload.job + payload.wbs + payload.activityCode + payload.activityDate.slice(0, 7);
      } else if (payload.type === 'QUOTE') {
        return item.type + item.job + item.activityCode + item.activityDate.slice(0, 7) === payload.type + payload.job + payload.activityCode + payload.activityDate.slice(0, 7);
      } else if (payload.type === 'NOREC' || payload.type === 'RDPRO') {
        return item.type + item.activityCode + item.activityDate.slice(0, 7) === payload.type + payload.activityCode + payload.activityDate.slice(0, 7);
      }

      return true;
    }
  });

  const groupedTimesheet = useMemo(() => {
    if (!timesheets) return {};

    const sorted = timesheets.sort((ts1, ts2) => {
      return TIMESHEET_TYPES.findIndex(({ value }) => value === ts1.type) - TIMESHEET_TYPES.findIndex(({ value }) => value === ts2.type)
        || ts1.job?.localeCompare(ts2.job)
        || ts1.wbs?.localeCompare(ts2.wbs)
        || ts1.activityCode?.localeCompare(ts2.activityCode);
    });

    const grouped = sorted.reduce((acc, ts) => {
      const key = `${ts.job}-${ts.wbs}-${ts.activityCode}`;
      const activityMonth = ts.activityDate?.slice(0, 7);

      if (activityMonth !== selectedMonth.value) return acc;
      if (!acc[ key ]) {
        acc[ key ] = {
          type: ts.type,
          job: ts.job,
          wbs: ts.wbs,
          activityCode: ts.activityCode,
          activityDescription: ts.type === 'JOB' ? (
            jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'QUOTE' ? (
            quotes.find(curr => curr.type === 'QUOTE' && curr.job === ts.job && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'NOREC' ? (
            norecovery.find(curr => curr.type === 'NOREC' && curr.activity === ts.activityCode)?.description
          ) : ts.type === 'RDPRO' ? (
            rdproject.find(curr => curr.type === 'RDPRO' && curr.activity === ts.activityCode)?.description
          ) : undefined,
          hours: {},
          pump: jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.pump,
          completed: jobs.find(curr => curr.type === 'JOB' && curr.job === ts.job && curr.wbs === ts.wbs && curr.activity === ts.activityCode)?.completed
        };
      }
      acc[ key ].hours[ ts.activityDate ] = { d: ts.activityDate, h: isNaN(+ts.activityHours) ? 0 : +ts.activityHours, id: ts.id };
      return acc;
    }, {});


    return grouped;
  }, [ timesheets, selectedMonth ]);
  //#endregion

  //#region [handlers] 
  const handleInsertTimesheet = useCallback(({ type, job, wbs, activityCode, activityDate, activityHours }) => {
    const totalHoursForDay = timesheets.reduce((acc, ts) => {
      if (ts.activityDate === activityDate) {
        return acc + +ts.activityHours;
      }
      return acc;
    }, 0);

    if (totalHoursForDay + activityHours > 18) {
      notify('Error', 'Total hours for the day cannot exceed 18', 'error');
      return;
    }

    insTimesheet({ type, job, wbs, activityCode, activityDate, activityHours });
  }, [ insTimesheet, timesheets ]);

  const handleUpdateTimesheet = useCallback(({ id, type, job, wbs, activityCode, activityHours, activityDate }) => {
    const totalHoursForDay = timesheets.reduce((acc, ts) => {
      if (ts.activityDate === activityDate && ts.id !== id) {
        return acc + +ts.activityHours;
      }
      return acc;
    }, 0);

    if (totalHoursForDay + activityHours > 18) {
      notify('Error', 'Total hours for the day cannot exceed 18', 'error');
      return;
    }

    updTimesheet({ id, type, job, wbs, activityCode, activityHours, activityDate });
  }, [ updTimesheet, timesheets ]);

  const handleDeleteTimesheet = useCallback(({ type, wbs, job, activityCode }) => {
    setGenericModal({
      label: 'Delete Timesheet',
      description: 'Are you sure you want to delete this row?',
      icon: { name: 'trash', type: 'rr', className: 'text-red-500' },
      submit: {
        label: 'Delete',
        onSubmit: () => {
          delTimesheet({ type, wbs, job, activityCode, month: selectedMonth.value });
          setGenericModal({ show: false });
        }
      },
      cancel: {
        label: 'Cancel',
        onCancel: ({ handleClose }) => handleClose()
      },
      show: true
    });
  }, [ delTimesheet, selectedMonth ]);
  //#endregion

  //#region [return]
  return (
    <TimesheetContext.Provider value={ {
      jobs, quotes, norecovery, rdproject, activities,
      selectedMonth, setSelectedMonth, notWorkDays, maybeWorkDays, holidays,
      groupedTimesheet, timesheets,
      handleInsertTimesheet, handleUpdateTimesheet, handleDeleteTimesheet
    } }>
      <div className="flex flex-col items-center w-full h-full overflow-y-auto">
        <table className='w-full border-separate border-spacing-0 [&_td]:border-b [&_th]:border-b [&_th:not(:last-child)]:border-r [&_td:not(:last-child)]:border-r'>
          <TimesheetHeader />

          <TimesheetBody
            timesheets={ timesheets }
            holidays={ holidays }
            onInsertTimesheet={ handleInsertTimesheet }
            onUpdateTimesheet={ handleUpdateTimesheet }
            onDeleteTimesheet={ handleDeleteTimesheet }
            notWorkDays={ notWorkDays }
            groupedTimesheet={ groupedTimesheet }
          />
        </table>
      </div>
    </TimesheetContext.Provider>
  );
  //#endregion
};
//#endregion

//#region [comp] TimesheetHeader - Header component for the timesheets page
const TimesheetHeader = () => {
  //#region [contexts]
  const { userData } = useAuth();
  const { selectedMonth, setSelectedMonth, holidays, timesheets, notWorkDays, maybeWorkDays, groupedTimesheet } = TimesheetConsumer();
  //#endregion
  ;
  const dict = DICTIONARY.en.timesheets.TimesheetHeader;

  //#region [computed]
  const jobHours = useMemo(() => timesheets?.reduce((acc, ts) => acc + (ts.activityDate?.substring(0, 7) === selectedMonth.value && ts.type === 'JOB' ? +ts.activityHours : 0), 0), [ timesheets, selectedMonth.value ]);

  const noRecoveryHours = useMemo(() => timesheets?.reduce((acc, ts) => acc + (ts.activityDate?.substring(0, 7) === selectedMonth.value && ts.type === 'NOREC' ? +ts.activityHours : 0), 0), [ timesheets, selectedMonth.value ]);

  const totalHours = useMemo(() => jobHours + noRecoveryHours, [ jobHours, noRecoveryHours ]);

  const totalNeedHours = useMemo(() => {
    return Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).reduce((acc, i) => {
      const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
      const day = new Date(date).getDay();
      if (!notWorkDays.includes(day) && !holidays.includes(date) && !maybeWorkDays.includes(day)) {
        return acc + 8;
      }
      return acc;
    }, 0);
  }, [ selectedMonth, notWorkDays, holidays ]);

  const jobPercent = useMemo(() => {
    const result = Math.round(jobHours / totalHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ jobHours, totalHours ]);

  const noRecoveryPercent = useMemo(() => {
    const result = Math.round(noRecoveryHours / totalHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ noRecoveryHours, totalHours ]);

  const totalPercent = useMemo(() => {
    const result = Math.round((totalHours) / totalNeedHours * 100);
    if (isNaN(result)) return 0;
    return result;
  }, [ totalHours, totalNeedHours ]);
  //#endregion

  //#region [return]
  return (
    <thead className='shadow-md sticky top-0 bg-white'>
      <tr>
        <th className='text-center' colSpan={ 4 }>{ userData.fullname }</th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth }>
          <input
            className='border-0 focus:ring-0 outline-none text-center w-full'
            type='month'
            value={ selectedMonth.value }
            onChange={ (e) => {
              if (new Date(e.target.value) !== 'Invalid Date') {
                setSelectedMonth(e.target.value);
              }
            } }
          />
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }>{ userData.email }</th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.job }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { jobHours }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { jobPercent }%
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }></th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.noRecovery }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { noRecoveryHours }
        </th>
        <th className='text-center' colSpan={ 3 }>
          { noRecoveryPercent }%
        </th>
      </tr>

      <tr>
        <th className='text-center' colSpan={ 4 }></th>
        <th className='text-center' colSpan={ selectedMonth.daysInMonth - 6 }>
          { dict.total }
        </th>
        <th className={ clsx('text-center', totalHours < totalNeedHours ? 'bg-red-300' : totalHours > totalNeedHours ? 'bg-blue-300' : 'bg-green-300') } colSpan={ 3 }>
          { jobHours + noRecoveryHours }/{ totalNeedHours }
        </th>
        <th className={ clsx('text-center', totalHours < totalNeedHours ? 'bg-red-300' : totalHours > totalNeedHours ? 'bg-blue-300' : 'bg-green-300') } colSpan={ 3 }>
          { totalPercent }%
        </th>
      </tr>

      {/** HEADER AND DATES */ }
      <tr>
        <th colSpan={ 4 }></th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
          const isToday = date === new Date().toISOString().split('T')[ 0 ];
          const day = new Date(date).getDay();
          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);
          return (
            <th
              key={ `dates-${selectedMonth.value}-${i}` }
              className={
                clsx(
                  isToday && 'ring-inset ring-2 ring-red-500',
                  isNotWorkDay && 'bg-gray-200'
                )
              }
            >
              { i }
            </th>
          );
        }) }
      </tr>

      {/** DAYS OF THE WEEKS */ }
      <tr>
        <th colSpan={ 4 }></th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
          const day = new Date(date).getDay();
          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);

          return (
            <th
              key={ `dayofweek-${selectedMonth.value}-${i}` }
              className={ clsx(
                "day",
                isNotWorkDay && 'bg-gray-200'
              ) }
            >
              { SHORT_DAY_NAMES[ day ] }
            </th>
          );
        }) }
      </tr>

      {/** TOTAL PER DAY */ }
      <tr>
        <th>Tipologia</th>
        <th>Commessa</th>
        <th>Bucket</th>
        <th>Attività</th>
        { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
          const date = new Date(selectedMonth.year, selectedMonth.month, i, 12);
          const dateString = date.toISOString().split('T')[ 0 ];
          const isOverToday = date > new Date();
          const total = Object.values(groupedTimesheet).reduce((acc, ts) => {
            return acc + (ts.hours[ dateString ]?.h ?? 0);
          }, 0);
          const day = date.getDay();

          const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(dateString);

          const color = isOverToday ? (
            maybeWorkDays.includes(day) ? (
              total > 0 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            ) : (
              total > 0 && total < 8 ? (
                'text-red-600'
              ) : total === 8 ? (
                'text-green-300'
              ) : total > 8 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            )
          ) : (
            maybeWorkDays.includes(day) ? (
              total > 0 ? (
                'text-blue-600'
              ) : (
                'text-black'
              )
            ) : (
              total < 8 ? (
                'text-red-600'
              ) : total === 8 ? (
                'text-green-300'
              ) : (
                'text-blue-600'
              )
            )
          );

          return (
            <th
              key={ `totalperday-${selectedMonth.value}-${i}` }
              className={ clsx(
                'text-center w-6 h-6 font-bold',
                color,
                isNotWorkDay && 'bg-gray-200'
              ) }
            >
              { !isNotWorkDay && total }
            </th>
          );
        }) }
      </tr>
    </thead>
  );
  //#endregion
};
//#endregion

//#region [comp] TimesheetBody - Body component for the timesheets page
const TimesheetBody = () => {
  //#region [contexts]
  const { setContextMenu } = useContextMenu();
  const { selectedMonth, handleInsertTimesheet, handleUpdateTimesheet, handleDeleteTimesheet, groupedTimesheet } = TimesheetConsumer();
  //#endregion

  //#region [computed]
  const timesheetValues = useMemo(() => Object.values(groupedTimesheet ?? []), [ groupedTimesheet ]);

  const editNotAllowed = useMemo(() => {
    const selected = new Date(selectedMonth.year, selectedMonth.month + 1, 1, 1);
    const now = new Date();
    return selected < now;
  }, [ selectedMonth ]);
  //#endregion

  //#region [handlers]
  const handleShowContextMenu = (e, timesheet) => {
    e.preventDefault();
    setContextMenu({
      context: [
        [
          {
            id: 'remove',
            name: 'Remove',
            icon: {
              name: 'trash',
              type: 'rr'
            },
            onClick: () => handleDeleteTimesheet({
              type: timesheet.type,
              wbs: timesheet.wbs,
              job: timesheet.job,
              activityCode: timesheet.activityCode
            })
          }
        ]
      ],
      position: {
        x: e.clientX,
        y: e.clientY
      }
    });
  };

  const handleCellValueChange = (payload) => {
    if (payload.id) {
      handleUpdateTimesheet(payload);
    } else {
      handleInsertTimesheet(payload);
    }
  };
  //#endregion

  //#region [return]
  return (
    <tbody>
      {/** TIMESHEETS */ }
      { timesheetValues.map((timesheet) => (
        <Sheet
          key={ `${timesheet.job}-${timesheet.wbs}-${timesheet.activityCode}` }
          timesheet={ timesheet }
          onContextMenu={ (e) => handleShowContextMenu(e, timesheet) }
          onChange={ handleCellValueChange }
          disabled={ editNotAllowed }
        />
      )) }

      <TimesheetCreateForm
        disabled={ editNotAllowed }
      />
    </tbody>
  );
  //#endregion
};
//#endregion

//#region [comp] Sheet - Component for each row in the timesheet
const Sheet = ({ onChange, onContextMenu, disabled, timesheet }) => {
  //#region [contexts]
  const { selectedMonth, holidays, notWorkDays } = TimesheetConsumer();
  //#endregion

  //#region [vars]
  const type = TIMESHEET_TYPES.find(({ value }) => value === timesheet.type)?.label ?? '-';
  
  const job = ![ 'NOREC' ].includes(timesheet.type) ? (timesheet.job ?? '-') : '-';

  const wbs = timesheet.wbs ?? '-';

  const activity = (timesheet.type === 'JOB' ? `[${timesheet.activityCode}] - ` : '') + timesheet.activityDescription ?? '-';
  //#endregion

  //#region [return]
  return (
    <tr className='h-[15px] *:h-[15px] hover:bg-gray-100' onContextMenu={ onContextMenu }>
      <td className='px-2'>{ type } </td>
      <td className='px-2'>{ job }</td>
      <td className='px-2'>{ wbs }</td>
      <td className='px-2'>{ activity }</td>
      { Array.from({ length: selectedMonth.daysInMonth }, (_, i) => i + 1).map(i => {
        const date = new Date(selectedMonth.year, selectedMonth.month, i, 12).toISOString().split('T')[ 0 ];
        const day = new Date(date).getDay();
        const currID = timesheet.hours[ date ]?.id ?? undefined;
        const isNotWorkDay = notWorkDays.includes(day) || holidays.includes(date);

        return !isNotWorkDay ? (
          <Field
            disabled={ disabled }
            key={ `field-${timesheet.job}-${timesheet.wbs}-${timesheet.activityCode}-${date}` }
            value={ (timesheet.hours[ date ]?.h ?? 0) > 0 ? timesheet.hours[ date ]?.h : '' }
            onChange={ (val) => {
              onChange({
                id: currID,
                type: timesheet.type,
                job: timesheet.job,
                wbs: timesheet.wbs,
                activityCode: timesheet.activityCode,
                activityDate: date,
                activityHours: val
              });
            } }
          />
        ) : (
          <td key={ date } className='w-7 h-7 text-center bg-gray-200'></td>
        );
      }) }
    </tr>
  );
  //#endregion
};
//#endregion

//#region [comp] Field
// Component for each field in the timesheet
const Field = ({ value: _value, onChange, disabled }) => {
  //#region [contexts]
  const { notify } = useNotifyContext();
  //#endregion

  //#region [states]
  const [ editMode, setEditMode ] = useState(false);
  const [ value, setValue ] = useState(_value);
  //#endregion

  //#region [handlers]
  const handleEditMode = (enable) => {
    if (enable && !disabled) {
      setEditMode(true);
    } else {
      setEditMode(false);
    }
  };

  const handleSubmit = (e) => {
    handleEditMode(false);
    if (isNaN(+value) || +value > 18 || +value < 0 || value % 0.25 !== 0) {
      notify('Invalid value', 'Please enter a valid number, between 0.25 and 18', 'error');
      setValue(_value);
    } else if ((value === '' && _value === 0) || value === _value) {
      setValue(_value);
    } else {
      onChange(value === '' ? 0 : +value);
      setValue(_value);
    }
  };
  //#endregion

  //#region [effects]
  useEffect(() => setValue(_value), [ _value ]);
  //#endregion

  //#region [return]
  return (
    <td className='w-7 h-7 text-center p-0'>
      { editMode ? (
        <input autoFocus
          type='text'
          className='w-full h-full p-0 text-center'
          value={ value }
          onChange={ (e) => setValue(e.target.value) }
          onKeyDown={ (e) => e.key === 'Enter' && handleSubmit(e) }
          onBlur={ handleSubmit }
        />
      ) : (
        <div
          className={ clsx(
            'w-full h-full text-center ring-inset ring-2 ring-transparent',
            disabled ? 'hover:ring-gray-500' : 'hover:ring-blue-500'
          ) }
          onClick={ () => handleEditMode(true) }
        >
          { _value ?? '' }
        </div>
      ) }
    </td>
  );
  //#endregion
};

//#endregion

//#region [comp] TimesheetCreateForm - Component for creating a new timesheet
const TimesheetCreateForm = ({ disabled }) => {
  //#region [contexts]
  const { notify } = useNotifyContext();
  const { selectedMonth, timesheets, handleInsertTimesheet, activities } = TimesheetConsumer();
  //#endregion

  //#region [costants]
  const defaultValues = { type: '', job: '', wbs: '', activityCode: '' };
  const dict = DICTIONARY.en.timesheets.TimesheetCreateForm;
  //#endregion

  //#region [states]
  const [ newTimesheet, setNewTimesheet ] = useNewTimesheet();
  //#endregion

  //#region [effects]
  useEffect(() => {
    setNewTimesheet(undefined);
  }, [ selectedMonth.value ]);
  //#endregion

  //#region [handlers]
  const handleTypeChange = (val) => setNewTimesheet((curr) => ({
    ...curr,
    type: val,
    job: [ 'NOREC' ].includes(val) ? val : '',
    wbs: [ 'QUOTE', 'NOREC' ].includes(val) ? '-' : [ 'RDPRO' ].includes(val) ? 'none' : '',
    activityCode: ''
  }));

  const handleJobChange = (val) => setNewTimesheet((curr) => ({ ...curr, job: val, activityCode: activities.find(act => act.type === 'RDPRO' && act.job === val)?.activity ?? undefined }));

  const handleWBSChange = (val) => setNewTimesheet((curr) => ({ ...curr, wbs: val }));

  const handleActivityChange = (val) => setNewTimesheet((curr) => ({ ...curr, activityCode: val }));

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!newTimesheet.type || !newTimesheet.activityCode || !newTimesheet.job || !newTimesheet.wbs) {
      return notify('Error', 'Please fill in all fields', 'error');
    }

    const activity = activities.find((act) => {
      return act.type === newTimesheet.type
        && act.job === newTimesheet.job
        && act.wbs === newTimesheet.wbs
        && act.activity === newTimesheet.activityCode;
    });

    if(activity === undefined) return notify('Error', 'Activity not found', 'error');

    const exists = timesheets.some((ts) => {
      return ts?.activityDate?.slice(0, 7) === selectedMonth.value
        && ts?.type === activity.type
        && ts?.job === activity.job
        && ts?.wbs === activity.wbs
        && ts?.activityCode === activity.activity;
    });

    if (exists) return notify('Error', 'Activity is already inserted', 'error');

    handleInsertTimesheet({
      ...newTimesheet,
      activityDate: `${selectedMonth.value}-01`,
      activityHours: 0
    });
    setNewTimesheet(undefined);
  };
  //#endregion

  //#region [computed]
  const jobList = useMemo(() => sieve(activities, {
    pre: () => newTimesheet?.type && newTimesheet?.type !== '-',
    filter: (act) => act.type === newTimesheet.type,
    nodouble: (item, curr) => item.value === curr.job,
    passback: curr => ({ value: curr.job, label: curr.job })
  }), [ newTimesheet, activities ]);

  const wbsList = useMemo(() => sieve(activities, {
    pre: () => newTimesheet?.type && newTimesheet?.type !== '-' && newTimesheet?.job && newTimesheet?.job !== '-',
    filter: (act) => act.type === newTimesheet?.type && act.job === newTimesheet.job,
    nodouble: (item, act) => item.value === act.wbs,
    passback: (act) => ({ value: act.wbs, label: act.wbs })
  }), [ newTimesheet, activities ]);

  const activityList = useMemo(() => sieve(activities, {
    pre: () => ['NOREC'].includes(newTimesheet?.type) || (newTimesheet?.job && newTimesheet?.job !== '-' && newTimesheet?.type && newTimesheet?.type !== '-'),
    filter: curr => curr.type === newTimesheet.type && curr.job === newTimesheet.job && curr.wbs === newTimesheet.wbs,
    passback: curr => ({ value: curr.activity, label: `[${curr.activity}] ${curr.description}` })
  }), [ newTimesheet, activities ]);

  const selectedActivity = useMemo(() => newTimesheet && activities.find((curr) => (
    curr.type === newTimesheet.type
    && curr.job === newTimesheet.job
    && curr.wbs === newTimesheet.wbs
    && curr.activity === newTimesheet.activityCode
  )), [ newTimesheet, activities ]);
  //#endregion

  //#region [vars]
  const hideJob = [ 'NOREC' ].includes(newTimesheet?.type);
  const disableJob = !newTimesheet?.type;

  const hideWBS = [ 'NOREC', 'QUOTE', 'RDPRO' ].includes(newTimesheet?.type);
  const disableWBS = !newTimesheet?.job;

  const hideActivity = [ 'RDPRO' ].includes(newTimesheet?.type);
  const disableActivity = !newTimesheet?.wbs && ![ 'NOREC', 'QUOTE', 'RDPRO' ].includes(newTimesheet?.type);
  //#endregion

  //#region [return]
  return (
    <tr className='h-[15px] *:h-[15px]'>
      { newTimesheet !== undefined ? (
        <>
          { /* Type Input */ }
          <td className='w-[150px]'>
            <FormifyCombobox
              className={ Style.FormifyCombobox.Table }
              value={ newTimesheet.type }
              onChange={ handleTypeChange }
            >
              { TIMESHEET_TYPES?.length > 0 && TIMESHEET_TYPES.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.job === value })) }
            </FormifyCombobox>
          </td>

          { /* Job Input */ }
          <td className='w-[200px]'>
            { !hideJob ? (
              <FormifyCombobox disabled={ disableJob } className={ Style.FormifyCombobox.Table } value={ newTimesheet.job } onChange={ handleJobChange } >
                { jobList?.length > 0 && jobList.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.job === value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { '-' }
              </div>
            ) }
          </td>

          { /* WBS Input */ }
          <td className='w-[150px]'>
            { !hideWBS ? (
              <FormifyCombobox
                disabled={ disableWBS }
                className={ Style.FormifyCombobox.Table }
                value={ newTimesheet.wbs }
                onChange={ handleWBSChange }
              >
                { wbsList?.length > 0 && wbsList.map(({ label, value }) => Style.FormifyCombobox.Table.ComboboxOption({ label, value, isSelected: newTimesheet.wbs === value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { newTimesheet?.wbs ?? '-' }
              </div>
            ) }
          </td>

          { /* Activity Input */ }
          <td className='w-[300px]'>
            { !hideActivity ? (
              <FormifyCombobox
                disabled={ disableActivity }
                className={ Style.FormifyCombobox.Table }
                value={ newTimesheet.activityCode }
                onChange={ handleActivityChange }
              >
                { activityList?.length > 0 && activityList.map((act) => Style.FormifyCombobox.Table.ComboboxOption({ label: act.label, value: act.value, isSelected: newTimesheet.activityCode === act.value })) }
              </FormifyCombobox>
            ) : (
              <div className='px-2'>
                { ['RDPRO'].includes(newTimesheet?.type) && newTimesheet?.job ? newTimesheet.job : '-' }
              </div>
            ) }
          </td>

          <td className='p-0' colSpan={ selectedMonth.daysInMonth }>
            <div className='flex h-full w-full'>
              <button
                type='button'
                className='flex-1 h-full w-full bg-green-400 text-white'
                onClick={ handleSubmit }
              >
                { dict.add }
              </button>
              <button
                type='button'
                className='flex-1 h-full w-full bg-red-400 text-white'
                onClick={ () => setNewTimesheet(undefined) }
              >
                { dict.cancel }
              </button>
            </div>
          </td>
        </>
      ) : !disabled && (
        <td className='p-0' colSpan={ selectedMonth.daysInMonth + 6 }>
          <div className='flex h-full w-full'>
            <button className='h-full w-full bg-green-400 text-white' type='button' onClick={ () => !disabled && setNewTimesheet(defaultValues) }>
              { dict.addNewRow }
            </button>
          </div>
        </td>
      ) }
    </tr>
  );
  //#endregion
};
//#endregion

//#region [hook] useNewTimesheet - Hook for handling the new timesheet
const useNewTimesheet = (initialValue = undefined) => {
  //#region [states]
  const [ data, setData ] = useState(initialValue ?? undefined);
  //#endregion

  //#region [return]
  return [
    data,
    setData
  ];
  //#endregion
};
//#endregion

//#region [hook] useSelectedMonth - Hook for handling the selected month
const useSelectedMonth = (_initValue) => {
  //#region [states]
  const [ selectedMonth, setSelectedMonth ] = useState(new Date(_initValue) !== 'Invalid Date' ? _initValue : new Date().toISOString().split('T')[ 0 ].slice(0, 7));
  //#endregion

  //#region [return]
  return [
    {
      value: selectedMonth,
      month: +selectedMonth.split('-')[ 1 ] - 1,
      year: +selectedMonth.split('-')[ 0 ],
      daysInMonth: new Date(selectedMonth.split('-')[ 0 ], selectedMonth.split('-')[ 1 ], 0).getDate()
    },
    (value) => {
      if (new Date(value) !== 'Invalid Date') {
        setSelectedMonth(value);
      }
    }
  ];
  //#endregion
};
//#endregion

export default TimesheetsPage;