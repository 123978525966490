import { Transition, TransitionChild } from "@headlessui/react";
import { useEffect } from "react";
import DashboardIcon from "../assets/icons/dashboard.png";
import { clsx } from "../modules/Utilkit/Utilkit";
import TimesheetsPage from "./apps/timesheets";
import { useAuth } from "./components/Auth";
import ContextMenu from "./components/ContextMenu";
import FloatPanel from "./components/FloatPanel";
import Navbar, { useNavigator } from "./components/Navigator";
import Notify from "./components/Notify";
import { useTitleBarTracker } from "./components/TitleBarTracker";

export default function App() {
	useEffect(() => console.log('current deploy: ' + process.env.REACT_APP_STAGE), []);


	const nav = [
		{ id: 'timesheets', name: 'Timesheets', image: DashboardIcon, default: true },
	];

	/* ==========================
		 = PUSH NOTIFICATIONS API =
		 ========================== */

	/*
	const [ notificationStatus, setNotificationStatus ] = useState(false);

	useEffect(() => {
		req('get-notification-status').then(res => res.success && setNotificationStatus(res.data.status ?? false));
	}, []);

	const handleNotificationSwitch = () => {
		if (notificationStatus) {
			// req('disable-notifications');
			setNotificationStatus(false);
		} else {
			if ('serviceWorker' in navigator) {
				// CHECK IF SERVICE WORKER IS ALREADY REGISTERED
				navigator.serviceWorker.getRegistrations().then((registrations) => {
					if (registrations.length === 0) {
						notify('Notifications denied', 'Service worker is not registered', 'error');
						console.error('Service worker is not registered');
						return;
					} else {
						console.log('Service worker registrations:', registrations);
					}

					Notification.requestPermission().then((perm) => {
						if (perm === 'granted') {
							// req('enable-notifications');
						} else {
							notify('Notifications denied', 'You denied the notification permission', 'error');
							console.error('Notification permission denied');
						}
					});

					navigator.serviceWorker.ready.then((reg) => {
						if (!reg.pushManager) {
							notify('Notifications denied', 'Cannot enable notifications', 'error');
							console.error('Push manager is not available');
							return;
						}

						reg.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: config.vapid.publicKey
						}).then((subscription) => {
							if (subscription) {
								console.log('Subscription successful:', JSON.stringify(subscription));
								// req('register-service-worker', { subscription });

								notify('Notifications enabled', 'Now you will receive notifications', 'success');
								setNotificationStatus(true);
							} else {
								console.error('Subscription failed');
								notify('Notifications denied', 'Cannot subscribe to notifications', 'error');
							}
						}).catch((error) => {
							console.error('Subscription error:', error);
							notify('Notifications denied', 'Subscription error', 'error');
						});
					}).catch((error) => {
						console.error('Service worker ready error:', error);
						notify('Notifications denied', 'Service worker ready error', 'error');
					});
				}).catch((error) => {
					console.error('Service worker registration error:', error);
					notify('Notifications denied', 'Service worker registration error', 'error');
				});
			} else {
				notify('Notifications denied', 'Your browser does not support notifications', 'error');
				console.error('Service worker is not supported by this browser');
			}
		}
	};
	*/

	const usernav = [
		{ id: 'logout', name: 'Log Out', style: 'text-red-600', className: "text-red-400", icon: { name: 'sign-out-alt', type: 'rr' } }
	];

	const { isOverlayVisible } = useTitleBarTracker();
	const { authStatus } = useAuth();
	const { go, active } = useNavigator();

	useEffect(() => {
		if(active && !nav.find(n => n.id === active)) {
			go(nav.find(n => n.default)?.id);
		}
	}, [])

	return (
		<div className="relative flex justify-center items-center w-full h-full">
			{ authStatus === "sign-in-required" && isOverlayVisible && (
				<div className="fixed w-full [-webkit-app-region:drag] h-8 top-0 left-0 z-50" />
			) }

			<Notify />
			<FloatPanel />
			<ContextMenu />

			<Transition
				show={ authStatus === 'success' }
				as="nav"
				className="fixed top-0 left-0 z-30 flex justify-start items-center px-1 bg-[#0050a3] h-[env(titlebar-area-height,33px)] w-full duration-150"
				enter="transition duration-500"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition duration-500"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<Navbar
					nav={ nav }
					usernav={ usernav }
				/>
			</Transition>

			<Transition
				show={ authStatus === 'success' }
				as="main"
				className={ clsx(
					"absolute flex justify-center items-center w-full h-full bg-white",
					authStatus === 'success' ? "z-20" : "z-[0!important]"
				) }
				enter="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				enterFrom="rounded-3xl w-[400px!important] h-[400px!important]"
				enterTo="rounded-0 w-full h-full"
				leave="transform duration-700 z-[0!important] transition-[width,height,border-radius]"
				leaveFrom="rounded-0 w-full h-full"
				leaveTo="rounded-3xl w-[400px!important] h-[400px!important]"
			>
				<TransitionChild
					as="div"
					className="w-full h-full"
					enter="transition duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<PageTransition page="timesheets">
						<TimesheetsPage />
					</PageTransition>
				</TransitionChild>
			</Transition>
		</div>
	);
};

const PageTransition = ({ page, children }) => {
	const { active } = useNavigator();

	return (
		<Transition
			show={ active === page }
			as="div"
			className={ clsx("w-full h-full pt-[env(titlebar-area-height,33px)]", active !== page && "absolute") }
			enter="transition duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition duration-300 absolute"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ children }
		</Transition>
	);

};