import { Transition } from "@headlessui/react";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import * as session from "../../modules/Stash/Stash";
import { clsx } from "../../modules/Utilkit/Utilkit";
import { useAuth } from "./Auth";
import { useTitleBarTracker } from "./TitleBarTracker";

import Logo from "../../assets/icon.png";
import SignOutIcon from "../../assets/icons/sign-out.png";
import Style from "../../assets/styles";
import { Contexture, ContextureButton, ContexturePanel, ContextureSwitch } from "../../components/Contexture/Contexture";
import Flaticon from "../../components/Flaticon/Flaticon";
import Modality from "../../components/Modality/Modality";

/* NAVBAR */
const Navbar = ({ nav, usernav }) => {
  const { isOverlayVisible } = useTitleBarTracker();
  const { handleSignOut } = useAuth();

  const [ showSignOutModal, setShowSignOutModal ] = useState(false);

  return (
    <div className={ clsx(
      "flex justify-between items-center select-none h-full w-[env(titlebar-area-width,100%)] duration-150 pl-1",
      isOverlayVisible && "pr-4"
    ) } >
      <SignOutModal
        show={ showSignOutModal }
        onClose={ () => setShowSignOutModal(false) }
        onSignOut={ () => {
          handleSignOut();
          setShowSignOutModal(false);
        } }
      />

      <NavbarLogo />

      <QuickAccessPanel />


      <div className="flex space-x-2 items-center">
        <NotificationMenu />

        <AppsMenu nav={ nav } />

        <UserMenu usernav={ usernav } onSignOut={ () => setShowSignOutModal(true) } />
      </div>

    </div>
  );
};

/* SIGN OUT MODAL */
const SignOutModal = ({ show, onClose, onSignOut }) => {
  return (
    <Modality
      show={ show }
      onClose={ onClose }
      label="Sign Out"
      image={ SignOutIcon }
      buttons={ [
        { name: "Cancel", onClick: onClose },
        { name: "Sign Out", onClick: onSignOut, styleSet: 'error' }
      ] }
      className="text-sm"
    >
      You want to sign out?
    </Modality>
  );
};

/* NAVBAR LOGO */
const NavbarLogo = () => {
  return (
    <div className={ clsx(
      "overflow-hidden flex space-x-1",
      "h-[calc(env(titlebar-area-height,33px)*0.7)] [-webkit-app-region:drag]"
    ) }>
      <img src={ Logo } alt="Trillium Logo" className="h-full select-none pointer-events-none" />
      <span className="text-white font-semibold">WDia</span>
    </div>
  );
};

/* QUICK ACCESS PANEL */
const QuickAccessPanel = () => {
  const { showQuickAccessPanel, quickAccessPanel } = useNavigator();
  const { isOverlayVisible } = useTitleBarTracker();

  return (
    <div className="flex-1 px-2 flex items-center">
      <Transition
        show={ showQuickAccessPanel }
        as="div"
        className="flex space-x-2 overflow-hidden px-2"
        enter="transition ease-out duration-300"
        enterFrom="opacity-0 translate-x-10"
        enterTo="opacity-100 translate-x-0"
        leave="transition ease-in duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        { quickAccessPanel.map(({ name, onClick, icon, type, onChange }) => (
          type === 'button' ? (
            <button
              key={ name }
              className="flex items-center justify-center px-1.5 space-x-1 hover:bg-blue-600 rounded-md text-white text-nowrap"
              onClick={ onClick }
            >
              <Flaticon name={ icon.name } type={ icon.type } size={ "calc(env(titlebar-area-height,33px)*0.4)" } />
              <span className="ml-2 text-[calc(env(titlebar-area-height,33px)*0.4)]">{ name }</span>
            </button>
          ) : type === 'search' && (
            <div className="relative flex" key={ name }>
              <Flaticon
                className="absolute top-1/2 -translate-y-1/2 right-1.5 text-gray-400"
                name={ icon?.name ?? 'search' }
                type={ icon?.type ?? 'rr' }
                size={ 16 }
              />

              <AdvancedInput placeholder={ name } onChange={ onChange } />
            </div>
          )
        )) }
      </Transition>

      { isOverlayVisible && (
        <div className="flex-1 w-full [-webkit-app-region:drag] h-[env(titlebar-area-height,33px)] top-0 left-0 z-50" />
      ) }
    </div>
  );
};

/* APPS MENU */
const AppsMenu = ({ nav }) => {
  const { active, go, setShowQuickAccessPanel } = useNavigator();
  return (
    <Contexture>
      <ContextureSwitch
        className={ clsx(
          "flex justify-center items-center rounded-full hover:bg-[#007DFF] h-fit w-fit duration-100 z-50",
          "w-[calc(env(titlebar-area-height,33px)*0.9)] h-[calc(env(titlebar-area-height,33px)*0.9)]"
        ) }
      >
        <Flaticon name="grid" type="sr" className="text-white" size="calc(env(titlebar-area-height,33px)*0.5)" />
      </ContextureSwitch>

      <ContexturePanel
        simple={ true }
        className="absolute w-[186px] p-2 top-9 right-1 rounded-xl shadow-lg border-gray-200 bg-white border grid grid-cols-2 gap-2"
        enter="transition ease-out duration-150 origin-top-right"
        enterFrom="scale-0 -translate-y-6 opacity-0"
        enterTo="scale-100 translate-y-0 opacity-100"
        leave="transition ease-in duration-150 origin-top-right"
        leaveFrom="scale-100 translate-y-0 opacity-100"
        leaveTo="scale-0 -translate-y-6 opacity-0"
      >
        { ({ onClose }) => {
          return (
            nav.map(({ id, name, icon, image, onClick }) => (
              <ContextureButton
                key={ id }
                as="button"
                className="flex flex-col w-20 justify-start rounded-lg items-center p-1.5 hover:bg-gray-100 space-y-1"
                onClick={ () => {
                  if (id === active) return;

                  if (onClick) {
                    onClick();
                    setShowQuickAccessPanel(false);
                    onClose();
                  } else {
                    setShowQuickAccessPanel(false);
                    go(id);
                    onClose();
                  }
                } }
              >
                { image ? (
                  <img src={ image } className="w-[38px]" />
                ) : (
                  <Flaticon name={ icon.name } type={ icon.type } size={ 36 } />
                ) }

                <div className="text-xs font-medium">{ name }</div>
              </ContextureButton>
            ))
          );
        } }
      </ContexturePanel>
    </Contexture>
  );
};

/* USER MENU */
const UserMenu = ({ usernav, onSignOut }) => {
  const { userData: { fullname, initials, username } } = useAuth();

  return (
    <Contexture>
      <ContextureSwitch
        className={ clsx(
          "rounded-full bg-green-500 p-0.5 duration-100 w-[calc(env(titlebar-area-height,33px)*0.8)]",
          "h-[calc(env(titlebar-area-height,33px)*0.8)]"
        ) }
      >
        <div className={ clsx(
          "bg-white rounded-full flex justify-center items-center text-center font-medium text-sm w-full h-full",
          "text-[calc(env(titlebar-area-height,33px)*0.4)]"
        ) }>
          { initials }
        </div>
      </ContextureSwitch>

      <ContexturePanel simple
        className={ clsx(
          "absolute w-[240px] p-2 z-50 top-10 right-1 rounded-xl shadow-lg border-gray-200 bg-white border",
          "flex flex-col justify-center items-center overflow-hidden"
        ) }
        enter="transition ease-out duration-150 origin-top-right"
        enterFrom="scale-0 -translate-y-6 opacity-0"
        enterTo="scale-100 translate-y-0 opacity-100"
        leave="transition ease-in duration-150 origin-top-right"
        leaveFrom="scale-100 translate-y-0 opacity-100"
        leaveTo="scale-0 -translate-y-6 opacity-0"
      >
        { ({ onClose }) => {
          return (
            <>
              <div className="absolute top-0 w-full h-8 bg-[#0050a3]" />

              <div className="flex justify-center items-center rounded-full bg-green-500 p-0.5 duration-100 w-12 h-12 z-10" >
                <div className="bg-white rounded-full w-full h-full flex justify-center items-center text-center font-medium text-xl shadow-lg">
                  { initials }
                </div>
              </div>

              <div className="flex flex-col w-full justify-center items-center mt-2 text-center">
                <div className="bg-gray-200 px-1 rounded-lg">{ username }</div>
                <div>{ fullname }</div>
                <div className="text-gray-400 text-xs">Software Version { process.env.REACT_APP_VERSION }</div>
              </div>

              <div className="flex flex-col w-full border-t pt-1 mt-2">
                { usernav.map(({ id, name, className, onClick, icon }) => {
                  return (
                    <button
                      key={ id }
                      className="flex w-full justify-between rounded-lg items-center p-1.5 duration-150 hover:bg-gray-100 space-x-2 px-2"
                      onClick={ () => {
                        if (id === 'logout') {
                          onSignOut();
                        } else {
                          onClick();
                        }
                        onClose();
                      } }
                    >
                      <div className={ className }>{ name }</div>
                      <Flaticon className={ className } name={ icon.name } type={ icon.type } size={ 20 } />
                    </button>
                  );
                }) }
              </div>
            </>
          );
        } }
      </ContexturePanel>
    </Contexture>
  );
};

/* NOTIFICATION LIST */
const NotificationMenu = () => {
  const { showNotificationList, setShowNotificationList } = useNavigator();
  const [ notifications, setNotifications ] = useState(session.getStore('notifications') ?? []);
  const [ loading, setLoading ] = useState(false);

  const syncNotification = async () => {
    setLoading(true);

    // REQUEST

    setLoading(false);
  };

  useEffect(() => {
    if (showNotificationList) {
      syncNotification();
    }
  }, [ showNotificationList ]);

  return (
    <Contexture>
      <ContextureSwitch
        className={ clsx(
          "flex justify-center items-center rounded-full hover:bg-[#007DFF] h-fit w-fit duration-100 z-50",
          "w-[calc(env(titlebar-area-height,33px)*0.9)] h-[calc(env(titlebar-area-height,33px)*0.9)]"
        ) }
        onClick={ () => setShowNotificationList(true) }
      >
        <Flaticon name="bell" type="sr" className="text-white" size="calc(env(titlebar-area-height,33px)*0.5)" />
      </ContextureSwitch>

      <ContexturePanel simple
        show={ showNotificationList }
        onClose={ () => setShowNotificationList(false) }
        className="absolute top-9 right-1 rounded-xl shadow-lg border-gray-200 bg-white border w-[500px]"
        enter="transition ease-out duration-150 origin-top-right"
        enterFrom="scale-0 -translate-y-6 opacity-0"
        enterTo="scale-100 translate-y-0 opacity-100"
        leave="transition ease-in duration-150 origin-top-right"
        leaveFrom="scale-100 translate-y-0 opacity-100"
        leaveTo="scale-0 -translate-y-6 opacity-0"
      >
        { ({ onClose }) => {
          return loading ? (
            <div className="flex justify-center items-center w-full h-full py-5">
              <Flaticon name="loading" type="rr" size={ 32 } className="text-gray-400 animate-spin" />
            </div>
          ) : (
            <div className="flex flex-col w-full h-full">
              <div className="flex justify-between items-center p-2 border-b">
                <div className="font-medium">Last Notifications</div>
              </div>

              <div className="flex flex-col w-full h-full overflow-y-auto">
                { notifications ? (
                  notifications.map(({ id, title, message, date, url }) => (
                    <div key={ id } className="flex flex-col w-full p-2 border-b last:border-0">
                      <div className="font-medium">{ title }</div>
                      <div className="text-sm text-gray-500">{ message }</div>
                      <div className="text-xs text-gray-400">{ date }</div>
                      { url && (
                        <div className="flex justify-end">
                          <button
                            className="text-xs text-blue-500"
                            onClick={ () => {
                              window.open(url, '_self');
                              onClose();
                            } }
                          >
                            View
                          </button>
                        </div>
                      ) }
                    </div>
                  ))
                ) : (
                  <div className="flex justify-center items-center w-full h-full text-gray-400 py-5">
                    No notifications
                  </div>
                ) }
              </div>
            </div>
          );
        } }
      </ContexturePanel>
    </Contexture>
  );
};

const AdvancedInput = ({ onChange, placeholder }) => {
  const timoutInput = useRef(null);
  const [ value, setValue ] = useState('');

  return (
    <input
      className={ Style.FormifyInput.Default }
      type="text"
      value={ value }
      placeholder={ placeholder }
      onChange={ (e) => {
        setValue(e.target.value);

        if (timoutInput.current) {
          clearTimeout(timoutInput.current);
        }

        timoutInput.current = setTimeout(() => {
          onChange(e.target.value);
        }, 500);

      } }
    />
  );
};

const NavigatorContext = createContext();

const NavigatorProvider = ({ children }) => {
  const [ active, setActive ] = useState('dashboard');
  const [ params, setParams ] = useState({});
  const [ showQuickAccessPanel, setShowQuickAccessPanel ] = useState(false);
  const [ quickAccessPanel, setQuickAccessPanel ] = useState([]);
  const [ showNotificationList, setShowNotificationList ] = useState(false);

  const go = (_active, _params = {}) => {
    if (_active !== active) {
      session.setSession({ active: _active, params: _params });
      setActive(_active);
      setParams(_params);
    }
  };

  useEffect(() => {
    // CHECK IF URL HAS ACTIVE AND PARAMS
    if (window.location.href.includes('active')) {
      const url = new URL(window.location.href);

      const _active = url.searchParams.get('active');
      const _params = url.searchParams.get('params') ? JSON.parse(url.searchParams.get('params')) : {};

      go(_active, _params);

      // CLEAR ALL DATA FROM URL
      window.history.pushState({}, document.title, window.location.pathname);
    } else if (session.getActive()) {
      go(session.getActive(), session.getParams() || {});
    }
  }, []);

  const value = {
    active,
    params,
    go,
    showQuickAccessPanel,
    setShowQuickAccessPanel,
    quickAccessPanel,
    showNotificationList,
    setShowNotificationList,
    setQuickAccessPanel: (items) => {
      if (showQuickAccessPanel) {
        setShowQuickAccessPanel(false);
      }

      setTimeout(() => {
        setQuickAccessPanel(items);
        setShowQuickAccessPanel(true);
      }, 350);
    }
  };

  return (
    <NavigatorContext.Provider value={ value }>
      { children }
    </NavigatorContext.Provider>
  );
};

const useNavigator = () => useContext(NavigatorContext);

export default Navbar;
export { NavigatorProvider, useNavigator };
