
const clsx = (...args) => args.filter(Boolean).join(" ");

const empty = (v) => {
  if (v === undefined || v === null || v === '' || v === false || v === 0 || v === '0' || v === false || (v instanceof Array && v.length === 0)) {
    return true;
  } else {
    return false;
  }
};

const switcher = (query, items = {}) => {
  if (items[ query ]) {
    return items[ query ];
  } else if (items[ "default" ]) {
    return items[ "default" ];
  } else {
    return false;
  }
};

const num = (val) => {
  return parseInt(val);
};

const flo = (val) => {
  return parseFloat(val);
}

const str = (val) => {
  return val.toString();
}

function dec(float, decimals = 2) {
  if (flo(float)) {
      if (decimals <= 0) {
          return Math.trunc(flo(float));
      } else {
          return Math.trunc(flo(float) * Math.pow(10, decimals)) / Math.pow(10, decimals);
      }
  } else {
      return float;
  }
}

/**
 * Filters and processes an array based on provided options.
 *
 * @param {Array} arr - The array to process.
 * @param {Object} [options={}] - The options for processing the array.
 * @param {Function} [options.predicate=() => true] - A function to test each element of the array. Returns true to keep the element, false otherwise.
 * @param {Function} [options.filter=() => false] - A function to test for duplicate elements. Returns true if the element is considered a duplicate.
 * @param {Function} [options.passback=(curr) => curr] - A function to transform elements before adding them to the result array.
 * @returns {Array} The processed array with elements filtered and transformed based on the provided options.
 */
const sieve = (arr, options = {}) => {
  const { filter, nodouble, passback, pre } = { pre: () => true, filter: () => true, nodouble: () => false, passback: (curr) => curr, ...options };

  if(pre(arr)) {
    return arr.reduce((acc, curr) => {
      const passed = filter(curr);

      if(passed) {
        const isDouble = acc.some((item) => nodouble(item, curr));

        if(!isDouble) {
          acc.push(passback(curr));
        }
      }
      return acc;
    }, []);
  } else {
    return [];
  }
}


export {
  clsx, dec, empty, flo, num, sieve, switcher
};
