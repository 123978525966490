import { Transition } from "@headlessui/react";
import { createContext, useContext, useEffect, useState } from "react";
import sls from "react-secure-storage";
import Logo from "../../assets/logo.png";
import Style from "../../assets/styles";
import Loady from "../../components/Loady/Loady";
import { DEFAULT_USER_NAME } from "../../config/costants";
import { useWebSocket } from "../../modules/Request";
import { clsx } from "../../modules/Utilkit/Utilkit";
import { useNotifyContext } from "../components/Notify";

//#region [comp] Auth - Component for show the authentication form
const Auth = () => {
  //#region [contexts]
  const { handleSignIn, authStatus } = useAuth();
  //#endregion

  //#region [states]
  const [ username, setUsername ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (authStatus === 'success') {
      setUsername("");
      setPassword("");
    }
  }, [ authStatus ]);
  //#endregion

  //#region [hooks]
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    await handleSignIn({ username, password });

    setIsLoading(false);
  };
  //#endregion

  //#region [return]
  return (
    <form onSubmit={ handleSubmit } >
      <div className="w-full h-full flex justify-center items-center">

        <Transition
          show={ authStatus === "sign-in-required" }
          as="div"
          style={ {
            width: "400px",
            height: "400px"
          } }
          className="p-10 space-y-5 flex justify-center items-center flex-col bg-white rounded-3xl"
          enter="transition duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-full space-y-2 flex flex-col justify-start items-center">
            <div className="mb-2 text-xl">
              <img src={ Logo } className="h-[130px]" />
            </div>
          </div>

          <div className="flex-1 flex justify-start items-center w-full relative">
            <Transition
              show={ !isLoading }
              as="div"
              className="w-full h-full absolute space-y-5"
              enter="transition duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="flex flex-col w-full space-y-2">
                <div className="flex flex-col w-full">
                  <label className="ml-1" htmlFor="username">Username</label>
                  <input name="username" required={ true } className={ Style.FormifyInput.Default } type="text" value={ username } onInput={ (e) => setUsername(e.target.value) } />
                </div>

                <div className="flex flex-col w-full">
                  <label className="ml-1" htmlFor="password">Password</label>
                  <input name="password" required={ true } className={ Style.FormifyInput.Default } type="password" value={ password } onInput={ (e) => setPassword(e.target.value) } />
                </div>
              </div>

              <div className="flex justify-end items-end space-x-2 w-full">
                <button
                  className={ clsx(
                    "border disabled:bg-green-300 disabled:border-green-300 disabled:text-green-100 disabled:cursor-not-allowed",
                    "px-4 py-0.5 duration-75 bg-green-500 hover:bg-green-400 border-green-400 hover:border-green-300 text-white",
                    "shadow-md rounded-md"
                  ) }
                >
                  Sign In
                </button>
              </div>
            </Transition>

            <Transition
              as="div"
              show={ isLoading }
              className="w-full absolute flex justify-center items-center"
              enter="transition duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Loady size={ 48 } color="#0050a3" />
            </Transition>
          </div>

        </Transition>
      </div>
    </form>
  );
  //#endregion
};
//#endregion

//#region [context] AuthContext - Context for authentication
const AuthContext = createContext();
//#endregion

//#region [provider] AuthProvider - Provider for authentication
const AuthProvider = ({ children }) => {
  //#region [contexts]
  const { account, setupToken } = useWebSocket();

  const { notify } = useNotifyContext();
  //#endregion

  //#region [states]
  const [ userData, setUserData ] = useState({});

  const [ authStatus, setAuthStatus ] = useState('loading');

  const [ authError, setAuthError ] = useState(null);
  //#endregion

  //#region [functions]
  const setFatalError = (error) => {
    setAuthError(error);
    setAuthStatus('error');
  };

  const handleSignIn = ({ username, password }) => {
    account('sign-in', (res) => {
      if (res.route === 'sign-in') {
        console.log(res);
        if (res.success) {
          console.log(res);

          sls.setItem('token', res.reply.token);
          setupToken(res.reply.token);

          const userData = res.reply.userData;
          const fullname = userData.name;
          const name = fullname?.split(' ')[ 0 ] ?? DEFAULT_USER_NAME;
          const surname = fullname?.split(' ')[ 1 ] ?? '';
          const initials = name[ 0 ] + surname[ 0 ] ?? '';
          const username = userData.username;
          const email = userData.email;
          const id = userData.id;
          const designCenter = userData.designCenter;
          const workplace = userData.workplace;
          //const permissions = res.reply.userPermissions;

          setUserData({ fullname, name, surname, initials, username, email, id, designCenter, workplace });

          notify('Success', 'You have successfully signed in!', 'default');
          setAuthStatus('success');
        } else {
          setAuthStatus('sign-in-required');

          if (res.error === 'invalid-credentials') {
            notify('Wrong Credentials', 'Combination of username and password is not valid!', 'error');
          } else if (res.error === 'password-expired') {
            notify('Password Expired', 'Please reset your password.', 'error');
          } else if (res.error === 'user-blocked') {
            notify('User Banned', 'You account is banned, please contact the administrator.', 'error');
          } else if (res.error === 'access-denied') {
            notify('Access Denied', 'You are not allowed to access this resource.', 'error');
          } else {
            notify('Unknown error', 'Please contact the administrator.', 'error');
          }
        }
      }
    }, { lifetime: 1000, immediateAsk: { username, password, agent: navigator.userAgentData }, priority: 999 });
  };

  const handleSignOut = () => {
    account('sign-out', (res) => {
      if (res.route === 'sign-out') {
        if (res.success) {
          sls.removeItem('token');
          setAuthStatus('sign-in-required');
        } else {
          setFatalError(res.error);
        }
      }
    }, { lifetime: 1000, immediateAsk: {}, priority: 999 });
  };
  //#endregion

  //#region [effects]
  useEffect(() => {
    const handleTokenAuthentication = async () => {
      account('sign-in-token', async (res) => {
        if (res.route === 'sign-in-token') {
          if (res.success) {
            const userData = res.reply.userData;
            const fullname = userData.name;
            const name = fullname?.split(' ')[ 0 ] ?? DEFAULT_USER_NAME;
            const surname = fullname?.split(' ')[ 1 ] ?? '';
            const initials = name[ 0 ] + surname[ 0 ] ?? '';
            const username = userData.username;
            const email = userData.email;
            const id = userData.id;
            const designCenter = userData.designCenter;
            const workplace = userData.workplace;
            //const permissions = res.reply.userPermissions;

            setUserData({ fullname, name, surname, initials, username, email, id, designCenter, workplace });

            notify('Success', 'You have successfully signed in!', 'default');
            setAuthStatus('success');
          } else {
            setAuthStatus('sign-in-required');
            if (res.error === 'invalid-token') {
              sls.removeItem('token');
              notify('Invalid Session', 'Your session is invalid or expired, please sign in again.', 'error');
            } else if (res.error === 'password-expired') {
              sls.removeItem('token');
              notify('Password Expired', 'Please reset your password.', 'error');
            } else if (res.error === 'user-blocked') {
              sls.removeItem('token');
              notify('User Banned', 'You account is banned, please contact the administrator.', 'error');
            } else if (res.error === 'access-denied') {
              sls.removeItem('token');
              notify('Access Denied', 'You are not allowed to access this resource.', 'error');
            } else {
              notify('Error', 'Try again later or contact the administrator.', 'error');
            }
          }
        }
      }, { lifetime: 1000, immediateAsk: { token: sls.getItem('token') }, priority: 999 });
    };

    if (sls.getItem('token')) {
      handleTokenAuthentication();
    } else {
      setAuthStatus('sign-in-required');
    }
  }, []);
  //#endregion

  //#region [return]
  return (
    <AuthContext.Provider value={ { authStatus, authError, handleSignIn, handleSignOut, setFatalError, userData } }>
      <div className="flex justify-center items-center w-full h-full absolute z-10">
        <Auth />
      </div>
      { children }
    </AuthContext.Provider>
  );
  //#endregion
};
//#endregion

//#region [hook] useAuth - Hook for authentication
const useAuth = () => useContext(AuthContext);
//#endregion

export default Auth;
export { AuthProvider, useAuth };
