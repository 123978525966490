const DICTIONARY = {
  en: {
    timesheets: {
      TimesheetCreateForm: {
        add: 'Add',
        cancel: 'Cancel',
        addNewRow: '+ Add New Row'
      },
      TimesheetHeader: {
        job: 'Job',
        quote: 'Quote',
        noRecovery: 'No Recovery',
        rdproject: 'R&D Project',
        total: 'Total', 
        
      }
    }
  }
}

export default DICTIONARY;