import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './app/components/Auth';
import { ContextMenuProvider } from './app/components/ContextMenu';
import { FloatPanelProvider } from './app/components/FloatPanel';
import { LoadingProvider } from './app/components/LoadingScreen';
import { NavigatorProvider } from './app/components/Navigator';
import { NotifyProvider } from './app/components/Notify';
import { TitleBarTrackerProvider } from './app/components/TitleBarTracker';
import App from './app';
import { WebSocketProvider } from './modules/Request';
import { GenericModalProvider } from './app/components/GenericModal';
import { FatalErrorProvider } from './app/components/FatalError';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <FatalErrorProvider>
    <GenericModalProvider>
      <LoadingProvider>
        <NotifyProvider>
          <TitleBarTrackerProvider>
            <WebSocketProvider>
              <AuthProvider>
                <NavigatorProvider>
                  <ContextMenuProvider>
                    <FloatPanelProvider>
                      <App />
                    </FloatPanelProvider>
                  </ContextMenuProvider>
                </NavigatorProvider>
              </AuthProvider>
            </WebSocketProvider>
          </TitleBarTrackerProvider>
        </NotifyProvider>
      </LoadingProvider>
    </GenericModalProvider>
  </FatalErrorProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
