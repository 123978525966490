import Ripples from '../../assets/loading-screen.svg';
import { createContext, useContext, useState } from 'react';

const LoadingContext = createContext();

const LoadingProvider = ({ children }) => {
  const [ showLoadingScreen, setShowLoadingScreen ] = useState(false);

  const value = {
    showLoadingScreen,
    setShowLoadingScreen
  };

  return (
    <LoadingContext.Provider value={ value }>
      { children }
      { showLoadingScreen && (
        <div className="fixed w-full h-full z-[60] flex justify-center items-center top-0 left-0 bg-black-50 backdrop-blur-md">
          <img className='w-24 h-24' src={ Ripples } alt="Loading Screen" />
        </div>
      ) }
    </LoadingContext.Provider>
  );

};

const useLoadingContext = () => useContext(LoadingContext);

export { LoadingProvider, useLoadingContext };