import { useState, useEffect, useRef, useCallback } from 'react';
import secureLocalStorage from 'react-secure-storage';
import config from '../config';
import * as stash from '../modules/Stash/Stash';
import { createContext, useContext } from 'react';
import { useFatalError } from '../app/components/FatalError';

/**
 * Custom hook to manage WebSocket requests and responses.
 * @param {string} route - The route for the WebSocket request.
 * @param {object} options - Options for managing the request, including functions like handleError, waitForResponse, priority and sort.
 * @returns {[object, Function, Function, Function]} - Returns the data, insert, update and remove functions.
 */
function useTracer(route, _options) {

  const options = { handleError: undefined, handleSelect: undefined, insertFilter: undefined, deleteFilter: undefined, waitForResponse: true, priority: 0, sort: undefined, onStartLoading: undefined, onFinishLoading: undefined, selectReqData: {}, ..._options };

  const { ask, follow, unfollow } = useWebSocket();
  const [ data, setData ] = useState(stash.getStore('ws-data-' + route) ?? []);
  const originalDataMap = useRef(new Map());

  useEffect(() => {
    follow(route, (res) => {
      if (res.route !== route) return;

      options.onFinishLoading && options.onFinishLoading();

      if (res.success) {
        const handleAction = {
          select: () => {
            const result = res.reply;
            stash.addStore('ws-data-' + route, result);
            setData(result);
          },
          insert: () => {
            setData((prev) => {
              const result = [
                ...prev.filter(({ requestId }) => res.requestId !== requestId),
                {
                  ...prev.find(({ requestId }) => res.requestId === requestId),
                  id: res.reply.id,
                  requestId: undefined
                }
              ];
              stash.addStore('ws-data-' + route, result);
              return result;
            });
          },
          "insert-share": () => {
            setData((prev) => {
              const result = [
                ...prev,
                {
                  ...res.reply
                }
              ]
              stash.addStore('ws-data-' + route, result);
              return result;
            });
          },
          "update-share": () => {
            setData((prev) => {
              const result = prev.map((item) => item.id === res.reply.id ? res.reply : item);
              stash.addStore('ws-data-' + route, result);
              return result;
            });
          },
          "delete-share": () => {
            setData((prev) => {
              const result = prev.filter((item) => options.deleteFilter ? options.deleteFilter(res.reply, item) : item.id !== res.reply.id);
              stash.addStore('ws-data-' + route, result);
              return result;
            });
          }
        };

        handleAction[ res.action ]?.();
        originalDataMap.current.delete(res.requestId);
      } else {
        const originalData = originalDataMap.current.get(res.requestId);

        if (originalData) {
          setData(originalData);
          originalDataMap.current.delete(res.requestId);
        }

        options?.handleError ? options.handleError(res.error) : console.error('Error: ', res.error);
      }
    });

    options.onStartLoading && options.onStartLoading();
    ask(route, options.selectReqData, 'select', options.waitForResponse, options.priority);

    return () => {
      unfollow(route);
    };
  }, [ route ]);

  useEffect(() => {
    if (Object.keys(options.selectReqData).length > 0) {
      options.onStartLoading && options.onStartLoading();
      ask(route, options.selectReqData, 'select', options.waitForResponse, options.priority);
    }
  }, [ ...Object.values(options.selectReqData) ]);
      

  const insert = (payload) => {
    setData((prev) =>{
      const requestId = ask(route, payload, 'insert');
      originalDataMap.current.set(requestId, prev);
      const result = [ ...prev, { ...payload, requestId } ];
      stash.addStore('ws-data-' + route, result);
      return result;
    });
  };

  const update = (payload) => {
    setData((prev) => {
      const requestId = ask(route, payload, 'update');
      originalDataMap.current.set(requestId, prev);
      const result = prev.map((item) => item.id === payload.id ? payload : item)
      stash.addStore('ws-data-' + route, result);
      return result;
    });
  };

  const remove = (payload) => {
    setData((prev) => {
      const requestId = ask(route, payload, 'delete');
      originalDataMap.current.set(requestId, prev);
      const result = prev.filter((item) => options.deleteFilter ? options.deleteFilter(payload, item) : item.id !== payload.id);
      stash.addStore('ws-data-' + route, result);
      return result;
    });
  };
  
  return [ options?.sort ? data.sort(options.sort) : data, insert, update, remove ];
}

const WebSocketContext = createContext(null);

/**
 * WebSocket provider component to manage WebSocket connections and requests.
 * @param {object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} - The WebSocket provider component.
 */
const WebSocketProvider = ({ children }) => {
  const { showError } = useFatalError();
  const socket = useRef(null);
  const handlers = useRef({});
  const token = useRef(secureLocalStorage.getItem('token') ?? undefined);
  const activeRequests = useRef([]);
  const requests = useRef([]);
  const reconnectInterval = useRef(null);

  const createWebSocketConnection = () => {
    try {
      socket.current = new WebSocket(config.websocket);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('WebSocket connection failed:', error);
      }
      return;
    }

    socket.current.onopen = () => {
      if (process.env.NODE_ENV === 'development') {
        ask('sign-in-token', { token: token.current }, 'select', true, 0);

        console.log('WebSocket connection established');
      }

      // Clear any existing reconnect intervals
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }

      // Re-send all active requests
      requests.current.forEach((pending) => {
        const requestId = generateRequestId();
        const request = { ...pending, requestId };

        if (process.env.NODE_ENV === 'development') {
          console.log('WebSocket ask:', request);
        }

        activeRequests.current.push(request);

        const sendRequest = () => {
          const send = () => {
            if (socket.current && socket.current.readyState === WebSocket.OPEN) {
              activeRequests.current = activeRequests.current.filter(({ requestId }) => requestId !== request.requestId);

              if (process.env.NODE_ENV === 'development') {
                console.log('WebSocket send:', request);
              }

              socket.current.send(JSON.stringify(request));

              return true;
            }

            return false;
          };

          if (!send()) {
            const interval = setInterval(() => {
              if (send()) {
                clearInterval(interval);
              }
            }, 500);
          }
        };

        if (request.waitForResponse) {
          const send = () => {
            const higherPriorityRequests = activeRequests.current.filter(activerRequest => activerRequest.priority > request.priority && activerRequest.route !== request.route);
            if (higherPriorityRequests.length === 0) {
              sendRequest();

              return true;
            }

            return false;
          }

          if (!send()) {
            const interval = setInterval(() => {
              if (send()) {
                clearInterval(interval);
              }
            }, 500);
          }
        } else {
          sendRequest();
        }
      });

      // Start ping interval to keep the connection alive
      const pingInterval = setInterval(() => {
        ask('ping', {}, 'ping', false, 0);
      }, 30000); // Ping every 30 seconds

      // Clear ping interval on close
      socket.current.onclose = () => {
        clearInterval(pingInterval);
        if (process.env.NODE_ENV === 'development') {
          console.log('WebSocket connection closed.');
        }
        // showError('WebSocket connection lost, attempting to reconnect...');

        // Attempt to reconnect every 5 seconds
        if (!reconnectInterval.current) {
          createWebSocketConnection();

          reconnectInterval.current = setInterval(() => {
            if (socket.current && socket.current.readyState === WebSocket.CLOSED) {
              createWebSocketConnection();
            }
          }, 5000);
        }
      };

    };

    socket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.current.onmessage = (event) => {
      const response = JSON.parse(event.data);

      if (handlers.current[response.route]) {
        if (process.env.NODE_ENV === 'development') {
          console.log('WebSocket response:', response);
        }

        handlers.current[response.route](response);
      }
    };
  };

  useEffect(() => {
    activeRequests.current = [];
    requests.current = [];

    createWebSocketConnection();

    return () => {
      if (socket.current) {
        socket.current.close();
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
      }
      requests.current = [];
      activeRequests.current = [];
    };
  }, []);

  const setupToken = (newToken) => {
    token.current = newToken;
  };

  /**
   * Sends a request via WebSocket.
   * @param {string} route - The route for the request.
   * @param {object} data - The data to be sent with the request.
   * @param {string} action - The action type (select, insert, update, delete).
   * @param {boolean} waitForResponse - Whether to wait for a response before sending the request.
   * @param {number} priority - The priority of the request.
   */
  const ask = (route, data = {}, action = 'select', waitForResponse = true, priority = 0) => {
    if (process.env.NODE_ENV === 'development') {
      // console.log('WebSocket ask:', route, data, action, waitForResponse, priority);
    }

    const requestId = generateRequestId();
    const request = { route, data, action, token: token.current, waitForResponse, priority, requestId };
    requests.current.push({ route, data, action, token: token.current, waitForResponse, priority });
    activeRequests.current.push(request);

    const sendRequest = () => {
      const interval = setInterval(() => {
        if (socket.current && socket.current.readyState === WebSocket.OPEN) {
          activeRequests.current = activeRequests.current.filter(({ requestId }) => requestId !== request.requestId);
          clearInterval(interval);

          if (process.env.NODE_ENV === 'development') {
            console.log('WebSocket send:', request);
          }

          socket.current.send(JSON.stringify(request));
        }
      }, 100);
    };

    if (waitForResponse) {
      const interval = setInterval(() => {
        const higherPriorityRequests = activeRequests.current.filter(req => req.priority > priority && req.route !== route);
        if (higherPriorityRequests.length === 0) {
          clearInterval(interval);

          sendRequest();
        }
      }, 500);
    } else {
      sendRequest();
    }

    return requestId;
  };

  /**
   * Registers a handler for a specific route.
   * @param {string} route - The route to follow.
   * @param {Function} handler - The handler function to be called on receiving a response.
   */
  const follow = (route, handler) => {
    handlers.current[ route ] = handler;
  };

  /**
   * Unregisters a handler for a specific route.
   * @param {string} route - The route to unfollow.
   */
  const unfollow = (route) => {
    delete handlers.current[ route ];
    activeRequests.current = activeRequests.current.filter(req => req.route !== route);
    requests.current = requests.current.filter(req => req.route !== route);
  };

  /**
   * Manages WebSocket requests and responses for a specific route.
   * @param {string} route - The route to manage.
   * @param {Function} handler - The handler function to be called on receiving a response.
   * @param {object} options - Options for managing the route.
   * @returns {[Function, Function]} - Returns functions to ask and unfollow the route.
   */
  const account = (route, handler, options = { lifetime: false, immediateAsk: false, waitForResponse: true, priority: 0 }) => {
    follow(route, handler);

    options = {
      lifetime: false,
      immediateAsk: false,
      waitForResponse: true,
      priority: 0,
      ...options
    };

    const _ask = (data = {}) => {
      ask(route, data, 'select', options.waitForResponse, options.priority);
    };

    const _unfollow = () => {
      unfollow(route);
    };

    if (options.lifetime) {
      setTimeout(unfollow, options.lifetime);
    }

    if (options.immediateAsk) {
      _ask(options.immediateAsk);
    }

    return [ _ask, _unfollow ];
  };

  const values = {
    socket: socket.current,
    handlers: handlers.current,
    ask,
    follow,
    unfollow,
    account,
    setupToken
  };

  return (
    <WebSocketContext.Provider value={ values }>
      { children }
    </WebSocketContext.Provider>
  );
};

/**
 * Custom hook to use the WebSocket context.
 * @returns {object} - The WebSocket context values.
 */
const useWebSocket = () => {
  return useContext(WebSocketContext);
};

const generateRequestId = () => '_' + Math.random().toString(36).substr(2, 9);

export {
  useTracer,
  WebSocketProvider,
  useWebSocket
};
