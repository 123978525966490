import Flaticon from "../components/Flaticon/Flaticon";
import { FormifyComboboxOption } from "../components/Formify/Formify";
import { clsx } from "../modules/Utilkit/Utilkit";

/**
 * @namespace Style
 * @property {Object} Component - Select component.
 * @property {Object} Component.Style - Select style.
 * @property {Object} Component.Style._Props - If start with underscore, it is a property that is not used directly in the component.
 * @property {Object} Component.Style.ClassName - If start without underscore, it is a class name that is used directly in the component.
 */
const Style = {
  FormifyCombobox: {
    Default: {
      ComboboxInput: "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0 focus:border-blue-600 invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium w-full",
      ComboboxInputArrowButton: "group absolute inset-y-0 right-0 px-3.5",
      ComboboxInputArrowIcon: "text-gray-500 group-data-[hover]:fill-white",
      ComboboxOption: ({ label, value, isSelected }) => {
        return (
          <FormifyComboboxOption
            key={ value }
            className={ clsx(
              "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
              "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
              isSelected ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
            ) }
            value={ value }
            label={ label }
          >
            <div className="flex space-x-2 label  duration-75 text-nowrap">
              <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", isSelected ? "visible" : "invisible") } />
              <div className="label">{ label }</div>
            </div>
          </FormifyComboboxOption>
        );
      }
    },
    Table: {
      ComboboxInput: "w-full focus:ring-0 outline-0 border-none py-0 px-2 disabled:bg-gray-200 disabled:text-gray-500",
      ComboboxInputArrowButton: "group absolute inset-y-0 right-0 px-3.5",
      ComboboxInputArrowIcon: "text-gray-500 group-data-[hover]:fill-white",
      ComboboxOptions: "z-50 border bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]",
      ComboboxOption: ({ label, value, isSelected }) => {
        return (
          <FormifyComboboxOption
            key={ value }
            className={ clsx(
              "w-full h-full flex py-1 space-x-2 hover:space-x-0 overflow-hidden items-center data-[focus]:bg-gray-200 cursor-pointer",
              "hover:items-start hover:flex-col duration-75 [&:hover>.label]:text-wrap [&:hover>.hashtags]:flex-wrap overflow-hidden text-ellipsis",
              isSelected ? "bg-green-200" : "hover:bg-gray-200 data-[focus]:bg-gray-200"
            ) }
            value={ value }
            label={ label }
          >
            <div className="flex space-x-2 label  duration-75 text-nowrap">
              <Flaticon name="check" type="rr" size={ 18 } className={ clsx("text-green-600", isSelected ? "visible" : "invisible") } />
              <div className="label">{ label }</div>
            </div>
          </FormifyComboboxOption>
        );
      }
    }
  },
  FormifyInput: {
    Default: "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0 focus:border-blue-600 invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium",
    Table: "w-full focus:ring-0 outline-0 border-none h-full"
  },
  FormifySelect: {
    Default: {
      Select: "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0 focus:border-blue-600 invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium flex items-center justify-start space-x-1 w-full",
      Options: "z-50 border bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
    },
    Table: {
      Select: "border border-gray-300 rounded-md px-1.5 py-0.5 focus:ring-0 focus:border-blue-600 invalid:border-red-600 disabled:text-gray-500 disabled:bg-gray-200 font-medium flex items-center justify-start space-x-1 w-full",
      Options: "z-50 border bg-white rounded-md divide-y empty:invisible shadow-[0_0_4px_0_#00000020]"
    }
  },
  ContexturePanel: "w-full border focus:outline-none border-gray-300 bg-white rounded-lg shadow-lg flex flex-col items-stretch justify-center p-1 min-w-36",
  ContextureButton: "flex justify-start rounded-md items-center px-3 py-0.5 hover:bg-blue-600 space-x-2 text-sm text-nowrap hover:text-white"
};

export default Style;